<template>
  <div>
    <h2 class="text-uppercase text-lg mb-6">Blog</h2>
    <SearchComponent placeholder="Search anything" class="p-3 mb-6" />
    <template v-for="(item, index) in blogs">
      <BlogList
        :title="item.title"
        :body="item.body"
        :key="index"
        @on-details="onDetails"
      >
        <div>
          {{ item.body }}
        </div>
      </BlogList>
    </template>
    <Dialog>
      <BlogDetails
        :title="title"
        :description="description"
      />
    </Dialog>
  </div>
</template>

<script>
import { Dialog } from "@/components";
import { mapMutations } from "vuex";
import BlogList from "@/components/molecule/top-menu/BlogList";
import BlogDetails from "@/components/molecule/top-menu/BlogDetails";
import SearchComponent from "@/components/atom/SearchBox";
import data from "@/data/top-navigation/blogs";
export default {
  name: 'FAQ-View',
  components: {
    Dialog,
    BlogList,
    BlogDetails,
    SearchComponent
  },
  data() {
    return {
      title: '',
      description: '',
      blogs: data.blogs
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onDetails() {
      this.title = 'What is the main importance things of APICAL Moc test';
      this.description = 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet, officiis velit ullam, distinctio aut aspernatur eligendi, quasi magnam neque perferendis numquam facilis. Dolorum odit sequi in perspiciatis eum consectetur aut.'
      this.dialog(true);
    }
  }
}
</script>