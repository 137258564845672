<template>
  <div class="my-6">
      <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-15">
              <img src="/images/blog-page-thumbnail.png" alt="">
          </div>
          <div class="md-layout-item md-size-85">
              <h2 class="text-2xl">{{ title }}</h2>
              <p class="text-base text-lg line-height-33 my-3">{{ body }}</p>
              <Button
                @click="$emit('on-details')"
                class="bg-victoria text-white rounded mx-0"
              >Detaisl</Button>
          </div>
      </div>
  </div>
</template>

<script>
import { Button } from "@/components"
export default {
    components: {
        Button
    },
    props: {
        title: {
            type: String,
            required: true
        },
        body: {
            type: String,
            required: true
        }
    }
}
</script>