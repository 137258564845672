<template>
  <div class="p-6" style="width: 560px">
      <img src="/images/blog-page-details.png" alt="">
      <h2 class="text-xl my-6">{{ title }}</h2>
      <p class="text-lg line-height-33">{{ description }}</p>

      <div class="my-6 flex justify-center">
          <md-button @click="dialog(false)" class="bg-victoria text-white rounded">Back</md-button>
      </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
    props: {
        title: {
            type: String
        },
        description: {
            type: String
        }
    },
    methods: mapMutations({
        dialog: "setShowDialog",
    })
}
</script>

<style>

</style>