const blogs = [];

for (let i = 0; i < 5; i++) {
    blogs.push({
        id: 1,
        title: 'What is the main importance things of APICAL Moc test',
        body: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet, officiis velit ullam, distinctio aut aspernatur eligendi, quasi magnam neque perferendis numquam facilis. Dolorum odit sequi in perspiciatis eum consectetur aut.'
    })
    
}

export default {
    blogs
}